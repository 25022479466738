<!--
 * @Autor: Nehc
 * @Date: 2020-12-03 11:20:04
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-30 15:53:42
-->
<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import IncludingOrNo from './promotion_area';
import Products from './promotion_product';
import Rules from './promotion_rule';

formCreate.component('IncludingOrNo', IncludingOrNo);
formCreate.component('Products', Products);
formCreate.component('Rules', Rules);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      cusName: '',
      cusInfo: {},
      templateId: '',
      templateCode: '',
      templateName: '',
      disableComponents: [],
      areaData: {},
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },

  async mounted() {
    // 促销新增基础表单
    this.rule = await this.getFormRule('dms_prom_policy_addInfo', 'CRM20210426000000196');
    // await this.rule.forEach((v) => {

    // });
    // 促销商品
    this.rule.push({
      type: 'Products',
      field: 'products',
      title: '',
      props: {
        functionCode: '',
        selection: [],
        idKey: 'id',
        promotionProduct: {},
        type: '',
        notView:
          this.formConfig.code !== 'view' && this.formConfig.row.promotionPolicyStatus !== 'running',
      },
      value: {
        originTableData: [],
        giftTableData: [],
        bothTableData: [],
        productsType: '',
        productsNum: '',
      },
    });
    // 促销规则
    this.rule.push({
      type: 'Rules',
      field: 'rules',
      title: '',
      props: {
        functionCode: '',
        selection: [],
        idKey: 'id',
        type: '',
        promotionRule: {},
        promotionRuleJson: {},
        notView:
          this.formConfig.code !== 'view' && this.formConfig.row.promotionPolicyStatus !== 'running',
      },
      value: {
        rules: [],
      },
    });
    // 促销范围
    this.rule.push({
      type: 'IncludingOrNo',
      field: 'nonOrNo',
      title: '',
      props: {
        scopeType: 'customer',
        notView:
          this.formConfig.code !== 'view'
          && this.formConfig.row.promotionPolicyStatus !== 'running',
        isAdd: true,
        areaList: [],
      },
      value: {},
      on: {
        change: (val) => {
          this.areaData = val;
        },
      },
    });
    this.reload(this.rule);
    // 编辑控制不可见性
    if (this.formConfig.row.promotionPolicyStatus === 'running') {
      this.disableComponents.push(
        'promotionPolicyCode',
        'promotionPolicyName',
        'muban',
        'beginTime',
        'multipleable',
        'firstOrder',
        'scopeType',
        'description',
        'actPlanNo',
        'feeRate',
        'chanel',
        'saleCompanyCode',
      );
    } else if (this.formConfig.row.promotionPolicyStatus === 'waiting') {
      this.disableComponents.push('promotionPolicyCode');
    } else if (this.formConfig.code === 'view') {
      this.disableComponents.push(
        'promotionPolicyCode',
        'promotionPolicyName',
        'muban',
        'beginTime',
        'endTime',
        'multipleable',
        'firstOrder',
        'scopeType',
        'description',
        'actPlanNo',
        'feeRate',
        'chanel',
        'saleCompanyCode',
      );
    }
    this.disableComponents.map((item) => {
      this.getRule(item).props.disabled = true;
      return true;
    });
  },

  methods: {
    formComplete() {
      if (this.formConfig.code !== 'add') {
        this.getData();
      }
    },
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'muban') {
        rowData.restful = '/dms/promotion/template/page';
        rowData.headers = { functionCode: 'select-meet-conditions' };
        rowData.refresh = true;
        rowData.optionsKey = {
          label: 'templateName',
          value: 'templateCode',
        };
        rowData.on.change = (val) => {
          this.templateId = rowData.options.filter((a) => a.templateCode === val)[0].id;
          this.templateName = rowData.options.filter((a) => a.templateCode === val)[0].templateName;

          request
            .get('/dms/promotion/policy/editinfo', {
              templateId: rowData.options.filter((a) => a.templateCode === val)[0].id,
            })
            .then((res) => {
              this.setValue({
                promotionType: res.result.templateEditVo.promotionType,
              });
              this.updateRules({
                products: {
                  props: {
                    type: res.result.templateEditVo.promotionProduct,
                    promotionProduct: res.result.promotionPolicyProduct,
                  },
                },
              });
              const promotionRule = {
                limiteds: res.result.promotionPolicyRule.rulesAsLimited,
                conditions: res.result.promotionPolicyRule.rulesAsCondition,
                calculates: res.result.promotionPolicyRule.rulesAsCalculate,
              };
              this.getRule('rules').props = {
                ...this.getRule('rules').props,
                promotionRule,
                promotionRuleJson: JSON.parse(JSON.stringify(promotionRule)),
              };

              // this.updateRules({
              //   rules: {
              //     props: {
              //       promotionRule,
              //       promotionRuleJson: promotionRule,
              //     },
              //   },
              // });
              this.updateRules({
                nonOrNo: {
                  props: {
                    promotionScope: res.result.promotionPolicyScope,
                  },
                },
              });
            });
        };
      } else if (rowData.field === 'promotionPolicyCode') {
        rowData.validate = [
          ...rowData.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      } else if (rowData.field === 'endTime' || rowData.field === 'beginTime') {
        rowData.props = {
          ...rowData.props,
          relation: {
            beginField: 'beginTime',
            endField: 'endTime',
          },
        };
      } else if (rowData.field === 'scopeType') {
        rowData.restful = '/mdm/mdmdictdata/tree';
        rowData.restfulParams = {
          dictTypeCode: 'dms_promotion_scope_host_type',
        };
        rowData.optionsKey = {
          value: 'dictCode',
          label: 'dictValue',
        };
        rowData.props = {
          ...rowData.props,
          dictList: [],
        };
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            const areaList = [];

            rowData.options
              .find((a) => a.label === val)
              .children.forEach((a) => {
                rowData.props.dictList.forEach((b) => {
                  if (a.dictCode === b.dictCode) {
                    areaList.push(a);
                  }
                });
              });
            this.getRule('nonOrNo').props.areaList = areaList;
          },
          optionCallback: () => {
            // if (!this.formConfig.selection[0]) {
            this.setValue({
              [`${rowData.field}`]: rowData.options[0].dictCode,
            });
            this.getAreaCode(rowData.options[0]);
            // }
          },
        };
      } else if (rowData.field === 'firstOrder' || rowData.field === 'multipleable') {
        rowData.on = {
          ...rowData.on,
          optionCallback: () => {
            if (this.formConfig.code === 'add') {
              this.setValue({
                [`${rowData.field}`]: 'N',
              });
            } else {
              this.setValue({
                [`${rowData.field}`]: this.formConfig.row[rowData.field],
              });
            }
          },
        };
      } else if (rowData.field === 'composeState') {
        rowData.on = {
          ...rowData.on,
          optionCallback: () => {
            if (this.formConfig.code === 'add') {
              this.setValue({
                [`${rowData.field}`]: 'Y',
              });
            } else {
              this.setValue({
                [`${rowData.field}`]: this.formConfig.row[rowData.field] ? 'Y' : 'N',
              });
            }
          },
        };
      }
      return rowData;
    },
    // 获取数据字典
    getAreaCode(data) {
      request
        .post('/mdm/mdmdictdata/batchDictSelect', ['promotion_policy_scope_type_new'])
        .then((res) => {
          const areaList = [];
          data.children.forEach((v) => {
            res.result.promotion_policy_scope_type_new.forEach((a) => {
              if (a.dictCode === v.dictCode) {
                areaList.push(a);
              }
            });
          });
          this.getRule('scopeType').props.dictList = res.result.promotion_policy_scope_type_new;
          this.getRule('nonOrNo').props.areaList = areaList;
        });
    },
    getData() {
      request.get(`/dms/promotion/v2/findDetailsById/${this.formConfig.row.id}`).then((res) => {
        this.updateRules({
          products: {
            props: {
              type: res.result.templateVo.promotionProduct,
            },
          },
        });
        const originTableData = [];
        const giftTableData = [];
        const bothTableData = [];
        switch (Number(res.result.templateVo.promotionProduct)) {
          // 仅含本品
          case 0: {
            if (res.result.productMap.currentProducts) {
              res.result.productMap.currentProducts.map((v) => {
                originTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                });
                return v;
              });
            }
            break;
          }
          case 3: {
            if (res.result.productMap.currentProducts) {
              res.result.productMap.currentProducts.map((v) => {
                originTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                });
                return v;
              });
            }
            break;
          }
          // 本品和赠品
          case 1: {
            if (res.result.productMap.currentProducts) {
              res.result.productMap.currentProducts.map((v) => {
                originTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                });
                return v;
              });
            }
            if (res.result.productMap.giftProducts) {
              res.result.productMap.giftProducts.map((v) => {
                giftTableData.push({
                  giftCode: v.productCode,
                  giftName: v.productName,
                  giftRatio: v.giftRatio,
                });
                return v;
              });
            }
            break;
          }
          // 本品映射赠品
          case 2: {
            // res.result.promotionPolicyProduct.productsCurrentY.map((v, k) => {
            //   const a = res.result.promotionPolicyProduct.productsCurrentN[k];
            //   bothTableData.push({
            //     productName: v.productName,
            //     productCode: v.productCode,
            //     productOrderQty: v.productOrderQty,
            //     productOrderQtyType: v.productOrderQtyType,
            //     giftCode: a.productCode,
            //     giftName: a.productName,
            //   });
            //   return v;
            // });
            if (res.result.productMap.currentProducts) {
              res.result.productMap.currentProducts.map((v) => {
                bothTableData.push({
                  productName: v.productName,
                  productCode: v.productCode,
                  productOrderQty: v.productOrderQty,
                  productOrderQtyType: v.productOrderQtyType,
                  giftCode: v.productCodeGift,
                  giftName: v.productNameGift,
                });
                return v;
              });
            }
            break;
          }
          default: {
            break;
          }
        }
        const { ruleMap } = res.result;
        if (ruleMap.limiteds) {
          ruleMap.limiteds.map((v) => {
            const row = v;
            row.selected = true;
            return row;
          });
        } else {
          ruleMap.limiteds = [];
        }
        if (ruleMap.conditions) {
          ruleMap.conditions.map((v) => {
            const row = v;
            row.selected = true;
            return row;
          });
        } else {
          ruleMap.conditions = [];
        }

        if (ruleMap.calculates) {
          ruleMap.calculates.map((v) => {
            const row = v;
            row.selected = true;
            return row;
          });
        } else {
          ruleMap.calculates = [];
        }

        const data = {
          ...res.result,
          scopeType: res.result.scopeType,
          beginTime: res.result.beginTime.split(' 00:00:00')[0],
          endTime: res.result.endTime.split(' 23:59:59')[0],
          description: res.result.description,
          firstOrder: res.result.firstOrder,
          multipleable: res.result.multipleable,
          promotionPolicyCode: res.result.promotionPolicyCode,
          promotionPolicyName: res.result.promotionPolicyName,
          muban: res.result.templateCode,
          templateId: res.result.templateId,
          templateName: res.result.templateName,
          actPlanNo: res.result.actPlanNo,
          feeRate: res.result.feeRate,
          chanel: res.result.chanel,
          saleCompanyCode: res.result.saleCompanyCode,
          composeState: res.result.composeState ? 'Y' : 'N',
          promotionType: res.result.promotionType,
          // saleCompanyName: res.result.saleCompanyName,
          products: {
            originTableData,
            giftTableData,
            bothTableData,
            productsType: res.result.productsOrderQtyType,
            productsNum: res.result.productsOrderQty,
          },
          rules: {
            rules: ruleMap,
          },
          nonOrNo: res.result.scopeMap,
        };
        this.getRule('nonOrNo').props.scopeType = res.result.scopeType;
        this.getRule('nonOrNo').props.isAdd = false;
        this.setValue(data);
      });
    },
    // 首字母大写
    toUpper(data) {
      return data.slice(0, 1).toUpperCase() + data.slice(1);
    },
    async submit(e) {
      let requestUrl = '/dms/promotion/v2/create';
      const a = []; // 赠品表
      if (!this.getFormData()) return false;
      // console.log(this.getFormData().rules.rules);
      this.getFormData().products.giftTableData.map((v) => {
        a.push({
          productCode: v.giftCode,
          productName: v.giftName,
          giftRatio: v.giftRatio,
        });
        return true;
      });
      const b = []; // 本品表
      this.getFormData().products.originTableData.map((v) => {
        b.push({
          productCode: v.productCode,
          productName: v.productName,
          productOrderQty: v.productOrderQty,
          productOrderQtyType: this.getFormData().products.productsType || v.productOrderQtyType,
        });
        return true;
      });
      // 映射表
      this.getFormData().products.bothTableData.map((v) => {
        // a.push({
        //   productCode: v.giftCode,
        //   productName: v.giftName,
        // });
        b.push({
          productNameGift: v.giftName,
          productCodeGift: v.giftCode,
          productName: v.productName,
          productCode: v.productCode,
          productOrderQty: v.productOrderQty,
          productOrderQtyType: v.productOrderQtyType,
        });
        return true;
      });
      let g = []; // 规则中计算规则
      if (this.getFormData().rules.rules.calculates.find((v) => v.selected === true) === undefined) {
        g = [];
      } else {
        g.push(this.getFormData().rules.rules.calculates.find((v) => v.selected === true));
      }
      g.map((v) => {
        const row = v;
        delete row.id;
        return row;
      });
      let h = []; // 规则中满足条件
      if (this.getFormData().rules.rules.conditions.find((v) => v.selected === true) === undefined) {
        h = [];
      } else {
        h.push(this.getFormData().rules.rules.conditions.find((v) => v.selected === true));
      }
      h.map((v) => {
        const row = v;
        delete row.id;
        return row;
      });
      const k = []; // 规则中限量控制
      if (this.getFormData().rules.rules.limiteds) {
        this.getFormData().rules.rules.limiteds.map((v) => {
          const rowData = v;
          if (v.selected === true) {
            delete rowData.id;
            k.push(rowData);
          }
          return true;
        });
      } else {
        this.getFormData().rules.rules.limiteds = [];
      }

      const scopeMap = {};
      for (const key in this.areaData) {
        if (this.areaData[key]) {
          const name = `scope${this.toUpper(key)}`;
          scopeMap[`${name}Y`] = this.areaData[key].dataY;
          scopeMap[`${name}N`] = this.areaData[key].dataN;
        }
      }
      const params = {
        scopeType: this.getFormData().scopeType,
        promotionPolicyCode: this.getFormData().promotionPolicyCode,
        promotionPolicyName: this.getFormData().promotionPolicyName,
        templateCode: this.getFormData().muban,
        templateId: this.templateId || this.getFormData().templateId,
        templateName: this.templateName || this.getFormData().templateName,
        beginTime: `${this.getFormData().beginTime} 00:00:00`,
        endTime: `${this.getFormData().endTime} 23:59:59`,
        firstOrder: this.getFormData().firstOrder,
        multipleable: this.getFormData().multipleable,
        description: this.getFormData().description,
        productsOrderQtyType: this.getFormData().products.productsType,
        productsOrderQty: this.getFormData().products.productsNum,
        actPlanNo: this.getFormData().actPlanNo,
        feeRate: this.getFormData().feeRate,
        chanel: this.getFormData().chanel,
        saleCompanyCode: this.getFormData().saleCompanyCode,
        composeState: this.getFormData().composeState === 'Y',
        promotionType: this.getFormData().promotionType,
        // saleCompanyName: this.getFormData().saleCompanyName,
        id: this.formConfig.row.id || '',
        productMap: {
          giftProducts: a,
          currentProducts: b,
        },
        ruleMap: {
          calculates: g,
          conditions: h,
          limiteds: k,
        },
        scopeMap,
      };

      if (this.formConfig.code === 'edit') {
        requestUrl = '/dms/promotion/v2/update';

        params.id = this.formConfig.row.id;
      }

      if (e === 2) {
        this.$emit('submit', {
          row: params,
          submitUrl: '/dms/promotion/hd/saveAndSubmit',
        });
      } else {
        request.post(requestUrl, params).then((res) => {
          if (res.code === 200) {
            this.$emit('onClose');
            this.$emit('onGetList');
          } else {
            this.$message.error(res.messge);
          }
        });
      }
    },
  },
};
</script>
