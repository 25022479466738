<!--
 * @Autor: Nehc
 * @Date: 2020-12-04 14:53:29
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-21 14:52:55
-->
<template>
  <div class="list-box">
    <hr />
    <!-- 本品表 -->
    <h3>促销商品</h3>
    <div v-show="type !== '2'">
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('origin')">添加本品</vxe-button>
        </template>
      </vxe-toolbar>
      <span v-if="type === '1'">
        <label>产品组合起订量：</label>
        <el-select
          v-model="productGroupType"
          class="renderSelect"
          placeholder="请选择类型"
          :disabled="!notView"
          @change="groupType"
        >
          <el-option
            v-for="(item, index) in ptypeList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="productGroupNum"
          class="renderInput"
          :placeholder="productGroupNumCallback(productGroupType)"
          :disabled="!notView"
          @change="groupNum"
        ></el-input>
      </span>
      <vxe-table
        v-show="type !== '2'"
        v-model="originTableData"
        :align="allAlign"
        :data="originTableData"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
      >
        <vxe-table-column
          field="productCode"
          title="本品编码"
        ></vxe-table-column>
        <vxe-table-column
          field="productName"
          title="本品名称"
        ></vxe-table-column>
        <vxe-table-column
          field="productOrderQtyType"
          title="单品起订量类型"
          v-if="type !== '1'"
          :edit-render="{
            name: '$select',
            options: this.ptypeList,
            props: {disabled: !notView}
          }"
        ></vxe-table-column>
        <vxe-table-column
          field="productOrderQty"
          title="单品起订量"
          :edit-render="{ name: '$input', props: { type: 'number', min: 0 ,disabled: !notView} }"
        ></vxe-table-column>
        <vxe-table-column title="操作" width="100" show-overflow v-if="notView">
          <template v-slot="{ row }">
            <vxe-button type="text" @click="deleteTable(row, 'origin')"
              >删除</vxe-button
            >
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>

    <!-- 赠品表 -->
    <div v-show="type === '1'">
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('gift')">添加赠品</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table v-model="giftTableData" :align="allAlign" :data="giftTableData" :edit-config="{ trigger: 'click', mode: 'cell' }">
        <vxe-table-column field="giftCode" title="赠品编码"></vxe-table-column>
        <vxe-table-column field="giftName" title="赠品名称"></vxe-table-column>
        <!-- <vxe-table-column field="giftRatio" title="比例">
           <template #default="{ row }">
             <el-input v-model="row.giftRatio" placeholder="请输入" size="mini"></el-input>
          </template>
        </vxe-table-column> -->
         <vxe-table-column
          field="giftRatio"
          title="比例"
          :edit-render="{ name: '$input', props: { type: 'number', min: 0 ,disabled: !notView} }"
        ></vxe-table-column>
        <vxe-table-column title="操作" width="100" show-overflow v-if="notView">
          <template v-slot="{ row }">
            <vxe-button type="text" @click="deleteTable(row, 'gift')"
              >删除</vxe-button
            >
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>

    <!-- 本品与赠品映射表 -->
    <div v-show="type === '2'">
      <vxe-toolbar v-if="notView">
        <template v-slot:buttons>
          <vxe-button @click="addTable('both')">添加本品</vxe-button>
        </template>
      </vxe-toolbar>
      <span>
        <label>产品组合起订量：</label>
        <el-select
          v-model="productGroupType"
          class="renderSelect"
          placeholder="请选择类型"
          :disabled="!notView"
          @change="groupType"
        >
          <el-option
            v-for="(item, index) in ptypeList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="productGroupNum"
          class="renderInput"
          :placeholder="productGroupNumCallback(productGroupType)"
          :disabled="!notView"
          @change="groupNum"
        ></el-input>
      </span>
      <vxe-table
        :align="allAlign"
        :data="bothTableData"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
      >
        <vxe-table-column
          field="productCode"
          title="本品编码"
        ></vxe-table-column>
        <vxe-table-column
          field="productName"
          title="本品名称"
        ></vxe-table-column>
        <vxe-table-column
          field="productOrderQtyType"
          title="单品起订量类型"
          :editRender="{
            name: 'ElSelect',
            options: this.ptypeList,
            props: {disabled: !notView}
          }"
        ></vxe-table-column>
        <vxe-table-column
          field="productOrderQty"
          title="单品起订量"
          :edit-render="{ name: '$input', props: { type: 'number', min: 0 , disabled: !notView} }"
        ></vxe-table-column>
        <vxe-table-column
          field="giftCode"
          title="赠品编码"
          :edit-render="{
            name: '$input',
            events: {focus: (rowIndex) => this.getGift(rowIndex,'in') },
            props: {disabled: !notView}
          }"
        ></vxe-table-column>
        <vxe-table-column field="giftName" title="赠品名称"></vxe-table-column>
        <vxe-table-column title="操作" width="100" show-overflow v-if="notView">
          <template v-slot="{ row }">
            <vxe-button type="text" @click="deleteTable(row, 'both')"
              >删除</vxe-button
            >
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'Products',
  components: {
    SelectConfig,
  },
  props: {
    value: Object,
    promotionProduct: Object,
    type: String,
    notView: Boolean,
  },
  watch: {
    type: {
      deep: true,
      immediate: true,
      handler(v) {
        this.originTableData = [];
        this.giftTableData = [];
        this.bothTableData = [];
        this.productGroupType = '';
        this.productGroupNum = '';
        // console.log(v);
      },
    },
    promotionProduct: {
      deep: true,
      immediate: true,
      handler(v) {
        // console.log(v);
        // 0-仅含本品
        // 1-任意本品和赠品
        // 2-本品赠品一一对应
        // 3-本品即赠品
      },
    },
    value(v) {
      console.log(v);
      this.originTableData = this.value.originTableData;
      this.giftTableData = this.value.giftTableData;
      this.bothTableData = this.value.bothTableData;
      this.productGroupType = this.value.productsType;
      this.productGroupNum = this.value.productsNum;
    },
  },
  data() {
    return {
      allAlign: null,
      productGroupType: '', // 产品组起订类型
      productGroupNum: '', // 产品组起订数量
      startNum: '',
      originTableData: [], // 本品列表
      giftTableData: [], // 赠品列表
      bothTableData: [], // 映射列表
      bothGift: [], // 映射赠品
      bothOrigin: [], // 映射本品
      rowsIndex: '', // 映射添加赠品的index
      modalType: '',
      ptypeList: [
        {
          label: '金额',
          value: 'amount',
        },
        {
          label: '数量',
          value: 'number',
        },
      ],
    };
  },
  methods: {
    changeSelect(val) {
      console.log(val);
    },
    getGift(rowIndex, val) {
      this.modalType = val;
      this.rowsIndex = rowIndex.rowIndex;
      let params = {};
      const list = this.bothGift;
      params = {
        functionCode: 'dms_add_gift',
        data: list,
        idKey: 'productCode',
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 弹窗选中新增到列表
    addTable(val) {
      this.modalType = val;
      let params;
      if (this.modalType === 'origin') {
        const selectionList = [];
        const list = this.originTableData;
        params = {
          functionCode: this.type === '1' ? 'dms_prom_product' : 'dms_prom_product_two',
          data: list,
          idKey: 'productCode',
          noReset: this.type !== '1',
        };
      } else if (this.modalType === 'gift') {
        const list = this.giftTableData;
        params = {
          functionCode: 'dms_prom_product',
          data: list,
          idKey: 'giftCode',
          choiceType: 'checkbox',
        };
      } else if (this.modalType === 'both') {
        const list = this.bothTableData;
        params = {
          functionCode: 'dms_prom_product',
          data: list,
          idKey: 'productCode',
          choiceType: 'checkbox',
        };
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 删除列表行
    deleteTable(row, val) {
      const afterDelete = this[`${val}TableData`].filter(
        (v) => row.productCode !== v.productCode,
      );
      this[`${val}TableData`] = afterDelete;
      this.value[`${val}TableData`] = afterDelete;
    },
    // 获取弹窗选中数据
    onGetSelect(val) {
      if (this.modalType === 'gift') {
        val.map((v) => {
          const rowData = v;
          rowData.giftCode = v.productCode;
          rowData.giftName = v.productName;
          rowData.giftRatio = '';
          return rowData;
        });
        this.value.giftTableData = val;
        this.giftTableData = val;
        console.log(this.giftTableData);
      } else if (this.modalType === 'origin') {
        const list = [];
        val.forEach((v) => {
          const rowData = v;
          rowData.productOrderQtyType = rowData.productOrderQtyType || '';
          list.push(rowData);
        });
        this.originTableData = JSON.parse(JSON.stringify(list));
        this.value.originTableData = this.originTableData;
      } else if (this.modalType === 'both') {
        const list = [];
        val.forEach((v) => {
          const rowData = v;
          rowData.productOrderQtyType = rowData.productOrderQtyType || '';
          rowData.giftCode = rowData.giftCode || '';
          rowData.giftName = rowData.giftName || '';
          list.push(rowData);
        });
        this.bothTableData = JSON.parse(JSON.stringify(list));
        this.value.bothTableData = this.bothTableData;
      } else if (this.modalType === 'in') {
        val.map((v) => {
          this.bothGift = [];
          this.bothGift.giftCode = v.productCode;
          this.bothGift.giftName = v.productName;
          return true;
        });
        this.bothTableData[this.rowsIndex].giftName = this.bothGift.giftName;
        this.bothTableData[this.rowsIndex].giftCode = this.bothGift.giftCode;
        this.value.bothTableData = this.bothTableData;
      }
    },
    roleChangeEvent({ row }) {
      console.log(row);
    },
    // 获取起订量类型
    groupType(val) {
      this.value.productsType = val;
    },
    productGroupNumCallback(val) {
      const name = this.ptypeList.find((v) => v.value === val);
      if (name) {
        return `请输入${name.label}`;
      }
      return '请输入数量';
    },
    // 获取起订量数量
    groupNum(val) {
      this.value.productsNum = val;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style scoped>
.renderInput {
  width: 120px;
  margin: 0 5px 10px;
  display: inline-block;
}
.renderSelect {
  width: 120px;
  margin: 0 5px 10px;
  display: inline-block;
}
</style>
