<template>
  <div class="list-box">
    <hr />
    <h3>促销范围</h3>
    <el-tabs v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane :label="item.dictValue"
                   v-for="(item,index) in areaList"
                   :key="index"
                   :name="item.dictCode"></el-tab-pane>
    </el-tabs>
    <el-row>
      <el-col :span="20"
              :offset="1">
        <h5>包含</h5>
        <vxe-toolbar v-if="notView">
          <template v-slot:buttons>
            <vxe-button @click="addTable(true)">添加</vxe-button>
            <vxe-button @click="beforeDelete(true)">删除</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign"
                  ref="dataY"
                  v-if="tableObj[activeName]"
                   @checkbox-all="({records}) => selectAllEvent(records)"
                   @checkbox-change="({records}) => selectChangeEvent(records)"
                   :data="tableObj[activeName].dataY">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column v-for="(config, index) in tableObj[activeName].column"
                            :key="index"
                            v-bind="config"></vxe-table-column>
        </vxe-table>
        <h5>非包含</h5>
         <vxe-toolbar v-if="notView">
          <template v-slot:buttons>
            <vxe-button @click="addTable(false)">添加</vxe-button>
            <vxe-button @click="beforeDelete(false)">删除</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign"
                  ref="dataN"
                  v-if="tableObj[activeName]"
                   @checkbox-all="({records}) => selectAllEvent(records)"
                   @checkbox-change="({records}) => selectChangeEvent(records)"
                   :data="tableObj[activeName].dataN">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column v-for="(config, index) in tableObj[activeName].column"
                            :key="index"
                            v-bind="config"></vxe-table-column>
        </vxe-table>
      </el-col>
    </el-row>
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>

</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'NoticeManageTableForm',
  components: {
    SelectConfig,
  },
  props: {
    scopeType: String,
    value: Object,
    notView: Boolean,
    isAdd: Boolean,
    areaList: Array,
  },
  watch: {
    value(val) {
      for (const i in val) {
        if (val[i]) {
          const name = this.toUpper(i.split('scope')[1]);
          const type = name.slice(name.length - 1);
          const name2 = name.slice(0, name.length - 1);
          this.tableObj[name2] = {
            dataY: type === 'Y' ? val[i] : [],
            dataN: type === 'N' ? val[i] : [],
          };
        }
      }
    },
    areaList(val) {
      if (!this.value) {
        this.tableObj = {};
      }
      val.forEach((v) => {
        this.tableObj[v.dictCode] = {
          dataY: [],
          dataN: [],
          column: [
            {
              field: 'scopeCode',
              title: `${v.dictValue}编码`,
            },
            {
              field: 'scopeName',
              title: `${v.dictValue}名称`,
            },
          ],
          ...this.tableObj[v.dictCode],
        };
      });

      this.activeName = val[0].dictCode;
      this.$emit('change', this.tableObj);
    },
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      formConfig: {
        tableUrl: '',
      }, // 弹框数据
      formName: 'Form',
      activeName: 'first',
      allAlign: null,
      column: [],
      tableData: [],
      tableObj: {},
      modalType: '', // 弹框包含非包含
      tabType: 'first',
      firstSelect: [],
      secondSelect: [],
    };
  },
  created() {},
  methods: {
    // 首字母小写
    toUpper(data) {
      return data.slice(0, 1).toLowerCase() + data.slice(1);
    },
    handleClick(tab) {
      const areaDetail = this.tableObj[tab.name];
    },
    addTable(val) {
      this.modalType = val;

      const params = {
        functionCode: 'dms_area',
        data: this.modalType ? this.tableObj[this.activeName].dataY : this.tableObj[this.activeName].dataN,
        idKey: 'scopeCode',
        paramData: {
          scopeType: this.activeName,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      const tableObj = JSON.parse(JSON.stringify(this.tableObj));
      if (this.modalType) {
        tableObj[this.activeName].dataY = val;
      } else {
        tableObj[this.activeName].dataN = val;
      }
      this.tableObj = tableObj;
      this.$emit('change', this.tableObj);
    },
    beforeDelete(val) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteTable(val);
        })
        .catch(() => {});
    },
    deleteTable(val) {
      const list = [];
      if (val) {
        this.tableObj[this.activeName].dataY.forEach((v) => {
          let isSelect = false;

          this.$refs.dataY.getCheckboxRecords().forEach((a) => {
            if (a.scopeCode === v.scopeCode) {
              isSelect = true;
            }
          });
          if (!isSelect) {
            list.push(v);
          }
        });
      }
      this.tableObj[this.activeName].dataY = list;
      this.$emit('change', this.tableObj);
    },
    selectChangeEvent(val, records) {
      this[val] = records;
    },
    selectAllEvent(val, records) {
      this[val] = records;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
</style>
